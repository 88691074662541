import GlobalModel from "./_GlobalURL";
import Api from "./_base"

export default class ExplodedService {
    constructor(){
        this.api = new Api("apiUrl")
    }

    GetHeader = (material_id, bom, alternative) => {
        // Fetch Header
        var d;
        var param = new Object();
        d = [{
            "url": GlobalModel.urlSAP+"exploded_view",
            "idx": "2",
            "param": {
                "material_id": material_id,
                "bom": bom,
                "alternative": alternative,
                "plant":"1A00"
            }
        }];
        param["i"] = "D6DD37AB-3BC5-11EC-A7D8-06D84B3ADD48";
        param["d"] = d;
        
        return this.api.init().post("", param);
    };

    GetDetail = (material_id, bom, alternative) => {
        var d
        var param = new Object()
        d = [{
            "url": GlobalModel.urlSAP+"exploded_view",
            "idx": "3",
            "param": {
                "material_id": material_id,
                "bom": bom,
                "alternative": alternative
            }
        }];
        param["i"] = "D6DD37AB-3BC5-11EC-A7D8-06D84B3ADD48"
        param["d"] = d

        return this.api.init().post("", param)
    }

    GetFamily = () => {
        var d
        var param = new Object()
        d = [{
            "url": GlobalModel.urlSAP+"exploded_param",
            "idx": "2",
            "param": {
                "MODE":"FP" //[FP = Family Product | MO = Model | VE = Version ]
            }
        }];
        param["i"] = "D6DD37AB-3BC5-11EC-A7D8-06D84B3ADD48"
        param["d"] = d

        return this.api.init().post("", param)
    }

    GetModel = (family) => {
        var d
        var param = new Object()
        d = [{
            "url": GlobalModel.urlSAP+"exploded_param",
            "idx": "3",
            "param": {
                "MODE":"MO", //[FP = Family Product | MO = Model | VE = Version ]
                "MAIN_IMG":"X",
                "FAMILY_PRODUCT" : family
                
            }
        }];
        param["i"] = "D6DD37AB-3BC5-11EC-A7D8-06D84B3ADD48"
        param["d"] = d

        return this.api.init().post("", param)
    }

    GetVersion = (model) => {
        var d
        var param = new Object()
        d = [{
            "url": GlobalModel.urlSAP+"exploded_param",
            "idx": "4",
            "param": {
                "MODE":"VE"
                ,"MODEL": model
                ,"MAIN_IMG":""
                ,"PLANT":"1A00"
            }
        }];
        param["i"] = "D6DD37AB-3BC5-11EC-A7D8-06D84B3ADD48"
        param["d"] = d

        return this.api.init().post("", param)
    }

    GetPng = (url) => {
        return this.api.init().get(url)
    }
}