import { useState } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import "./NavigationSidebarConstant.css"

import { FcHome, FcInspection, FcElectronics } from "react-icons/fc";
import { FiLogOut } from "react-icons/fi";
import { RiSettingsLine } from "react-icons/ri";
import { Button, Nav, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from '../services/redux/AuthSlice'
import { SwalConfirmation } from "./SwalHelper";

const NavigationSidebarConstant = (props) => {
    const { activeMenu, toggleMenu } = props

    const user = useSelector(selectCurrentUser)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [isExpanded, setExpended] = useState(true)
    const [active, setActive] = useState(activeMenu)

    const menuItems = [
        {
            key: "Home",
            icon: <FcHome size={30}/>,
            link: "/home",
            text: "Homepage"
        },
        {
            key: "Exploded",
            icon: <FcElectronics size={30}/>,
            link: "/exploded",
            text: "Exploded Parts"
        },
        {
            key: "Report ASC Visit",
            icon: <FcInspection size={30}/>,
            link: "/report",
            text: "Report ASC Visit"
        },
    ]

    return(
        <section className="component-navigation-sidebar">
            <div className="side-nav-container text-dark">
                <div className="nav-upper">
                    <div className="nav-body pt-4">
                        <Nav className="justify-content-center flex-column">
                            <Row className="mb-4">
                                <Nav.Link onClick={()=>navigate('/change-password')} className={active==="User"
                                ?"nav-user-menu nav-user-active my-1 rounded text-center"
                                :"nav-user-menu my-1 rounded text-center"}>
                                    <Row className="justify-content-center text-center">
                                        <Col className="nav-user-icon py-2 col-6">
                                            {(user.Name).split(' ')[0][0]}
                                            {(user.Name).split(' ').length>1?(user.Name).split(' ')[1][0]:""}
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Row>
                            {
                                menuItems.map(({key, icon, link, text}) => {
                                    return (
                                        <Row>
                                            <Nav.Link onClick={()=>navigate(link)} className={active===key
                                            ?"nav-body-menu nav-body-active my-1 rounded text-center"
                                            :"nav-body-menu my-1 rounded text-center"}>
                                                <Row className="justify-content-center text-center">
                                                    <Col className="nav-menu-icon py-2 col-6">
                                                        {icon}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="nav-menu-text">
                                                        <b>{text}</b>
                                                    </div>
                                                </Row>
                                            </Nav.Link>
                                        </Row>
                                    )
                                })
                            }
                            <Row className="nav-menu-footer">
                                <Nav.Link onClick={()=>navigate('/home')} className={active==="User"
                                ?"nav-footer-menu nav-user-active my-1 rounded text-center"
                                :"nav-footer-menu my-1 rounded text-center"}>
                                    <Row className="justify-content-center text-center">
                                        <Col className="nav-footer-icon py-2 col-6">
                                            <RiSettingsLine/>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                                <Nav.Link onClick={() => {
                                        SwalConfirmation({
                                            text:"You will exit from your account.",
                                            onConfirm: () => {
                                                dispatch(logOut())
                                                navigate('/')
                                            }
                                        })
                                    }} 
                                className={active==="User"
                                ?"nav-footer-menu nav-user-active my-1 rounded text-center"
                                :"nav-footer-menu my-1 rounded text-center"}>
                                    <Row className="justify-content-center text-center">
                                        <Col className="nav-footer-icon py-2 col-6">
                                            <FiLogOut/>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Row>
                        </Nav>
                    </div>
                </div>
            </div>
        </section>
    )
}

NavigationSidebarConstant.propTypes = {
    activeMenu : PropTypes.string,
    toggleMenu : PropTypes.func.isRequired
}

NavigationSidebarConstant.defaultProps = {
    activeMenu : "None"
}

export default NavigationSidebarConstant