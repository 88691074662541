import Login from './pages/auth/login';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/home/dashboard';
import Report from './pages/report/report';
import { useSelector } from 'react-redux';
import { selectLoginState } from "./services/redux/AuthSlice"
import Reset from './pages/auth/reset';
import ChangePassword from './pages/auth/changePassword';
import Exploded from './pages/exploded/exploded';
import ExplodedExternal from './pages/exploded/exploded-external';
import ResetEmail from './pages/auth/resetEmail';
import TrackingExternal from './pages/container/tracking-external';

function App() {
  const isLoggedIn = useSelector(selectLoginState)
  return (
    <div className='app'>
      {
        !isLoggedIn
        ?<Routes>
          {/* Auth */}
          <Route path="/*" element={<Login/>} />
          <Route path="/reset-password" element={<Reset/>} />
          <Route path="/reset-password/:token" element={<ResetEmail/>} />
          {/* Unauthorized Routes */}
          <Route path="/exploded-list/:material_id/:bom/:alternative" element={<ExplodedExternal/>} />
          <Route path="/check/:ttsid/:serial" element={<TrackingExternal/>} />
          <Route path="/check/:ttsid" element={<TrackingExternal/>} />
        </Routes>
        :<Routes>
            {/* Global */}
            <Route path="/*" element={<Dashboard/>} />

            {/* User */}
            <Route path="/change-password" element={<ChangePassword/>} />

            {/* Home Page */}
            <Route path="/home" element={<Dashboard/>} />

            {/* Report */}
            <Route path="/report" element={<Report/>} />

            {/* Report */}
            <Route path="/exploded" element={<Exploded/>} />

            <Route path="/check/:ttsid/:serial" element={<TrackingExternal/>} />
            <Route path="/check/:ttsid" element={<TrackingExternal/>} />

            {/* Unauthorized Routes */}
            <Route path="/exploded-list/:material_id/:bom/:alternative" element={<ExplodedExternal/>} />
          </Routes>
      }
    </div>
  );
}

export default App;
