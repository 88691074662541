import Api from "./_base"

export default class AuthService {
    constructor(){
        this.api = new Api("apiUrl")
    }
  
    Authenticate = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'459E4DE4-34F9-43DB-BA66-7CF10178F489',
            d:encodedParams
        });
    };

    ResetPassword = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'A795A2AD-E035-4872-9EE8-0514E6F9C40F',
            d:encodedParams
        });
    };

    ChangePassword = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'BE1863F7-2FC5-431F-9BBF-908177838241',
            d:encodedParams
        });
    };

    ChangePasswordByToken = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'17594B95-94A4-411A-BC43-9967D4DE5346',
            d:encodedParams
        });
    };
}