import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Col, Container, Row, Card, Form, Alert, Button } from "react-bootstrap"
import NavigationBar from "../../components/NavigationBar"
import AuthService from "../../services/api/auth"
import logocosmos from "../../assets/images/icons/logocosmos.png"

const Reset = () => {
    // API
    const authService = new AuthService()

    //Config
    const userRef = useRef()
    const errRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // User Data
    const [user, setUser] = useState('')
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        userRef.current.focus()
    },[])

    useEffect(() => {
        setErrMsg('')
    },[user])

    // Handling Login
    const handleSubmit = async(e) => {
        e.preventDefault()
        
        authService.ResetPassword([{
            'Type':'Apps/Web',
            'Email':user
        }]).then((res) => {
            const ReturnMessage = res.data[0].ReturnMessage
            if(ReturnMessage === "OK"){
                setErrMsg("Please check your email inbox/spam folder to reset your account")
            }else{
                setErrMsg(ReturnMessage)
            }
        }).catch((err) => {
            if (!err?.originalStatus) {
                setErrMsg('No Server Response');
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus()
        })  
    }

    return (
        <section className="page-reset">
            <header className="text-center">
                <NavigationBar authorized={false}/>
            </header>
            <main>
                <Container>
                    <Row className="d-flex align-items-center justify-content-center" style={{
                        height:"100vh",
                    }}>
                        <Col lg={6}>
                            <Card className="border-0">
                                <Card.Body>
                                    <div className="text-center p-5">
                                        <Row className="d-flex justify-content-center">
                                            <Col>
                                                <img src={logocosmos} className="img-fluid" alt="Cosmos Logo"></img>
                                            </Col>
                                        </Row>
                                        <div className="h4 font-weight-bold">Reset Account</div>
                                    </div>
                                    <Form onSubmit={handleSubmit} autoComplete="off">
                                        <Alert variant="danger" ref={errRef} className={errMsg?"":"d-none"}>{errMsg}</Alert>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                type='text'
                                                placeholder='Enter your username/email...'
                                                name='username'
                                                id='username'
                                                ref={userRef}
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required
                                            />
                                        </Form.Group>

                                        <Row className="text-right" align="right">
                                            <Col className='d-inline'>
                                                <Button className="mx-2" type="submit" variant="danger">
                                                    Reset
                                                </Button>
                                                <Button type="button" onClick={()=>navigate('/')}>
                                                    Kembali
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
            <footer></footer>
        </section>
    )
}

export default Reset