import Api from "./_base"

export default class ReportService {
    constructor(){
        this.api = new Api("apiUrl")
    }

    GetAllEmail = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'9E49BB8F-43EC-4EA2-98F1-561B3A160A02',
            d:encodedParams
        });
    }

    GetAllCustomer = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'6EA75971-269A-447F-8A7E-F1DBEE1B5EAD',
            d:encodedParams
        });
    }

    GenerateReport = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'88CEB302-3F2B-494D-9D6C-4CEE963DDB18',
            d:encodedParams
        });
    }
}