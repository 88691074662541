import { Col, Container, Row, Form, Button, Card } from "react-bootstrap"
import { FaLayerGroup } from "react-icons/fa"
import HomeLayout from "../_layout/HomeLayout"

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ReactSVGPanZoom, fitToViewer, UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom"
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import { useWindowSize } from '@react-hook/window-size'
import { isEmptyArray, useFormik } from "formik"

// Global Variable
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-react-data-tools";
import { GridColumn, Grid } from "@progress/kendo-react-grid";
import ExplodedService from "../../services/api/exploded";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { SelectPicker } from "rsuite";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { SwalClose, SwalFire, SwalLoading } from "../../components/SwalHelper";

const Exploded = () => {    
    const explodedService = new ExplodedService()
    const [width, height] = useWindowSize({initialWidth: 0, initialHeight: 0})

    // Query Field
    const [family, setFamily] = useState([])
    const [model, setModel] = useState([])
    const [version, setVersion] = useState([])
    
    // Viewer Variables
    const [svg, setSvg] = useState(null)
    const [png, setPng] = useState(null)
    const [svgDOM, setSvgDOM] = useState(null)
    const Viewer = useRef(null)
    const [viewerState, setViewerState] = useState(false)
    const [interactiveState, setInteractiveState] = useState(true)
    const [header, setHeader] = useState(null)
    const [detail, setDetail] = useState(null)
    const [displayState, setDisplayState] = useState(null)

    // Kendo Utility
    const [partData, setPartData] = useState(null)
    const [filename, setFilename] = useState(null)
    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(10)
    const _export = useRef(null)
    const _grid = useRef(null)

    const onPageChange = (e) => {
        setSkip(e.page.skip)
        setTake(e.page.take)
    }

    const initialSort = [{
        field: 'ASCName',
        dir: 'asc'
    }]

    const [sort, setSort] = useState(initialSort)

    const kendoRowRender = (trElement, props) => {
        const checkId = /^\d+$/.test(props.dataItem.line)
        const trProps = {
            className: checkId ? 'part-parent' : 'part-child',
            id: 'KendoRow'+props.dataItem.line
        };
        return React.cloneElement(
            trElement,
            {
            ...trProps,
            },
            trElement.props.children
        );

    }

    //To Check Viewer
    useEffect(() => {
        if(!viewerState && Viewer.current){
            Viewer.current.fitToViewer()
            setViewerState(true)
        }
        
        if(!interactiveState && partData != null && svg != null){
            setupInteractiveUtils()
        }
    })

    // FORMIK VALIDATOR
    const validate = (values) => {
        const errors = {}
    
        if(!values.family){
            errors.family = 'Please pick a family product'
        }

        if(!values.model){
            errors.model = 'Please pick a product model'
        }

        if(!values.version){
            errors.version = 'Please pick a version'
        }
    
        return errors
    }

    //Formik Validator
    const formik = useFormik({
        initialValues: {
            family:"",
            model:"",
            version:"",
        },
        onSubmit: (values) => {
            const family = values.family
            const model = values.model
            const version = values.version.split(',')
            const bom = version[0]
            const alternatives = version[1]

            fetchHeader(model,bom,alternatives)
        },
        validate
    })

    //Constructor
    useEffect(() => {
        // Initialize Family Product
        setModel([])
        setVersion([])
        explodedService.GetFamily().then((res) => {
            setFamily(res.data.map( item => ({
                label: item.bezei,
                value: item.mvgr2
            })))
        })
    },[])

    // on QUERY VIEWER
    // [1] Get Header
    const fetchHeader = (material_id, bom, alternative) => {
        SwalLoading()
        // Fetch Header
        explodedService.GetHeader(material_id, bom, alternative).then((res) => {
            const data = res.data[0]
            if(data.url_external !== ""){                
                setHeader({
                    maktx: data.maktx,
                    matnr: data.matnr,
                })
    
                // Continue to setup SVG Viewer
                fetchDetail(model,bom,alternative)
                
                // SVG PNG VIEWER
                const fileType = data.url_external.split('.')[data.url_external.split('.').length-1]

                // Reset PNG SVG Data
                setPng(null)
                setSvg(null)
                
                if(fileType.toLowerCase() === "svg"){
                    setupExplodedSvg(data.url_external)
                }else{
                    setupExplodedPng(data.url_external)
                }

                // Display Viewer Container
                setDisplayState("founded")
            }else{
                // Display Unfounded Warning
                setDisplayState("notfound")
                SwalClose()
            }
        })
    }

    // [2.1] SVG Setup
    const setupExplodedSvg = async (url) => {
        setViewerState(false)
        axios.get(url).then((res) => {
            let frag = document.createRange().createContextualFragment(res.data)

            setSvg({
                source: url,
                height: frag.firstChild.getAttribute('height'),
                width: frag.firstChild.getAttribute('width')
            },[])
        })
    }

    // [2.2] PNG Setup
    const setupExplodedPng = async (url) => {
        setViewerState(false)
        setPng(url)
        SwalClose()
    }

    

    // [3] Get Detail
    const fetchDetail = async (material_id, bom, alternative) => {
        // Fetch Detail
        explodedService.GetDetail(material_id, bom, alternative).then((res) => {
            const data = res.data

            // Initialize kendo
            setSort(initialSort)
            setFilename("EXPLODED_"+material_id+"-"+bom+"-"+alternative)
            setPartData(data)

            // Initialize Interactive Utils
            setInteractiveState(false)
        })
    }

    // [4] Setup Interactive Util
    const setupInteractiveUtils = () => {
        setTimeout(() => {
            partData.forEach((part) => {
                const partId = part.line
                const checkId = /^\d+$/.test(partId)
                
                if(checkId){
                    const partItem = document.querySelector('g#Partsitem'+partId)
                    const partNumber = document.querySelector('g#No'+partId)
    
                    if(partItem != null && partNumber != null){
                        // ON ENTER SVG
                        partItem.addEventListener('mouseenter', (e) => {
                            e.stopPropagation()
                            document.querySelectorAll('.part-parent').forEach((e) => {
                                e.classList.remove('bg-success')
                            })
                            document.querySelector('#KendoRow'+partId).classList.add('bg-success')
                            selectKendoGridRows(partId)
                        })
    
                        partNumber.addEventListener('mouseenter', (e) => {
                            e.stopPropagation()
                            document.querySelectorAll('.part-parent').forEach((e) => {
                                e.classList.remove('bg-success')
                            })
                            document.querySelector('#KendoRow'+partId).classList.add('bg-success')
                            selectKendoGridRows(partId)
                        })
    
                        //ON ENTER TABLE ROW
                        document.querySelector('#KendoRow'+partId).addEventListener('mouseenter', () => {
                            Viewer.current.fitToViewer()
                            const bbox = document.getElementById('Partsitem'+partId).getBBox()
                            Viewer.current.fitSelection(bbox.x-20, bbox.y-20, bbox.width+50, bbox.height+30, 0)

                            // Colorize SVG PART
                            document.querySelectorAll('.part-vector').forEach((e) => {
                                e.setAttribute('stroke','black')
                            });
                            
                            let divChild = document.getElementById('Partsitem'+partId).children
                            for (let i = 0; i < divChild.length; i++) {
                                const e = divChild[i];
                                e.setAttribute('stroke','green')
                            }
                        })
    
                        //Setup path id
                        let divChild = document.getElementById('Partsitem'+partId).children
                        for (let i = 0; i < divChild.length; i++) {
                            const e = divChild[i];
                            e.classList.add('part-vector')
                            e.classList.add('part-vector-'+partId)
                        }
                    }
                }
            });
            setInteractiveState(true)
            SwalClose()
        }, 1000);
    }

    // [4.1] Kendo Row Selected
    const selectKendoGridRows = (id) => {
        // Scroll to a row
        var tableContainer = document.querySelector('.k-grid-content'),
            selectedRow = document.querySelector('#KendoRow'+id)

        selectedRow.scrollIntoView()

        // Colorize SVG PART
        document.querySelectorAll('.part-vector').forEach((e) => {
            e.setAttribute('stroke','black')
        });
        
        let divChild = document.getElementById('Partsitem'+id).children
        for (let i = 0; i < divChild.length; i++) {
            const e = divChild[i];
            e.setAttribute('stroke','green')
        }
    }

    // Component start here
    return (
        <HomeLayout activeSidebar="Exploded">
            <section className="page-exploded my-4">
                <Row className="mb-5">
                    <Col className="d-inline-flex">
                        <FaLayerGroup size={70}/>
                        <div className="px-3">
                        <h1 className="mb-0">Exploded Part List</h1>
                        <div style={{
                            color: "gray",
                            paddingLeft: 3
                        }}>
                            Explorer
                        </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* Form section to query part list */}
                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Family Product
                                        </Form.Label>
                                        {
                                            family.length===0
                                            ?<SelectPicker className="w-100" data={family} loading/>
                                            :<SelectPicker 
                                                className="w-100" 
                                                data={family} 
                                                value={formik.values.family}
                                                onChange={(val) => {
                                                    SwalLoading()
                                                    
                                                    formik.setValues({
                                                        family: val,
                                                        model: '',
                                                        version: '',
                                                    })
                                                    setVersion([])
                                                    setModel([])
                                                    // Initialize Model
                                                    val!==null ?
                                                    explodedService.GetModel(val).then((res) => {
                                                        if(res.data.length > 0){
                                                            setModel(res.data.map( item => ({
                                                                label: item.maktx,
                                                                value: item.matnr
                                                            })))

                                                            SwalClose()
                                                        }else{
                                                            SwalFire("Failed", "No data found. Please choose another family product.", 'error')
                                                        }
                                                    })
                                                    :
                                                    SwalClose()
                                                }}/>
                                        }
                                        {formik.errors.family && formik.touched.family ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.family}</div>
                                        ):null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Model
                                        </Form.Label>
                                        {
                                            model.length===0
                                            ?<SelectPicker className="w-100" data={model} loading/>
                                            :<SelectPicker 
                                                className="w-100" 
                                                data={model} 
                                                value={formik.values.model}
                                                onChange={(val) => {
                                                    formik.setValues({
                                                        family: formik.values.family,
                                                        model: val,
                                                        version: '',
                                                    })
                                                    setVersion([])

                                                    // Initialize Version
                                                    val !== null &&
                                                    explodedService.GetVersion(val).then((res) => {
                                                        setVersion(res.data.map( item => ({
                                                            label: item.stktx,
                                                            value: item.stlnr+","+item.stlal
                                                        })))
                                                    })
                                                }}/>
                                        }
                                        {formik.errors.model && formik.touched.model ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.model}</div>
                                        ):null}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Version
                                        </Form.Label>
                                        {
                                            version.length===0
                                            ?<SelectPicker className="w-100" data={version} loading/>
                                            :<SelectPicker 
                                                className="w-100" 
                                                data={version} 
                                                value={formik.values.version}
                                                onChange={(val) => {
                                                    formik.setFieldValue('version', val)
                                                }}/>
                                        }
                                        {formik.errors.version && formik.touched.version ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.version}</div>
                                        ):null}
                                    </Form.Group>
                                </Col>
                                <Col className="pt-4 mt-2">
                                    <Button className="btn-primary" id="btn-view" type="submit">View Parts</Button>
                                </Col>
                            </Row>
                        </Form>
                        {/* End of Form section */}


                        {/* Not Initialized Section */}
                        <Row className="my-5 justify-content-center" hidden={displayState==="notfound"?false:true}>
                            <Col md={6} className="text-center">
                                <Card className="text-center">
                                    <Card.Header>Load Failed</Card.Header>
                                    <Card.Body>
                                        <Card.Title>WE ARE SORRY!</Card.Title>
                                        <Card.Text>
                                            Exploded view for this version is not initialied yet, please try another version.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">Contact us if something went wrong</Card.Footer>
                                </Card>
                            </Col>
                        </Row>

                        {/* Exploded Section */}
                        <Row className="my-5" hidden={displayState==="founded"?false:true}>
                            {/* SVG Section */}
                            <Col md={8}>
                                {/* Header */}
                                {
                                    header != null
                                    ?<Row>
                                        <Col className="h3 w-100" style={{fontWeight: "bold"}}>
                                            {header.maktx}
                                        </Col>
                                        <Col className="d-flex justify-content-end h3 text-secondary">
                                            {header.matnr}
                                        </Col>
                                    </Row>
                                    :null
                                }
                                <Row className="border border-2 border-dark rounded" style={{
                                    position:"relative",
                                    overflow: "hidden",
                                    height:"85%"
                                }}>
                                    {/* SVG Viewer */}
                                    {
                                        svg==null
                                        ?null
                                        :<ReactSvgPanZoomLoader src={svg.source} render={(content) => {
                                            return (
                                                (
                                                    <UncontrolledReactSVGPanZoom
                                                        ref={Viewer}
                                                        height={height*0.85}
                                                        width={width*0.47}
                                                        className="svg-content"
                                                    >
                                                        <svg
                                                            width={svg.width}
                                                            height={svg.height}
                                                        >
                                                        {content}
                                                        </svg>
                                                    </UncontrolledReactSVGPanZoom>
                                                )
                                            )
                                        }} />
                                    }

                                    {/* Simple Image Viewer */}
                                    {
                                        png==null
                                        ?null
                                        :<ReactPanZoom
                                            style={{
                                                
                                            }}
                                            image={png}
                                            alt="Exploded component image."
                                        />
                                    }
                                    
                                </Row>
                            </Col>
                            {/* End of SVG Section */}

                            {/* Detail Section */}
                            <Col className="px-5" md={4}>
                                <Row className="h3">
                                    <center>Part Detail</center>
                                </Row>

                                {/* Kendo Container */}
                                <Row>
                                    <Col id="container-kendo-grid">
                                        {   partData!=null && filename!=null &&
                                            <section>
                                                <Row className="">
                                                    <Col className="text-center">
                                                        <Button variant="success" onClick={() => {
                                                            if (_export.current !== null) {
                                                                
                                                                _export.current.save(partData, _grid.current.columns);
                                                            }
                                                        }}>
                                                            Export To Excel
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                    <div className="overflow-auto my-2 rounded" style={{
                                                        height: 700,
                                                    }}>
                                                    <ExcelExport ref={_export} fileName={filename}>
                                                        <Grid 
                                                            className="mh-50 overflow-auto"
                                                            rowRender={kendoRowRender}
                                                            ref={_grid}
                                                            data={orderBy(partData, sort)}
                                                            total={partData.length}
                                                            scrollable={true}   
                                                            pageable={false}
                                                            sortable={true}
                                                            sort={sort}
                                                            onSortChange={e => {
                                                                setSort(e.sort)
                                                            }}
                                                            reorderable={true}
                                                            groupable={true}  
                                                            resizable={true}           

                                                            // IN CASE NEED PAGINATION
                                                            skip={skip}
                                                            take={take}
                                                            onPageChange={onPageChange}
                                                        >
                                                            <GridColumn
                                                                title= "No. "  dataType= "string"  field= "line"  width={60}  editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Material Number"  dataType= "string"  field= "idnrk"  width={120}  editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Description"  dataType= "string"  field= "maktx"  width={250}  editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Qty"  dataType= "string"  field= "menge"  width={60}  editor= {false}  editable= {true}
                                                            />
                                                        </Grid>
                                                    </ExcelExport>
                                                </div>
                                            </section>
                                        }
                                    </Col>
                                </Row>
                                {/* End of Kendo Container */}

                            </Col>
                            {/* End of Detail Section */}
                        </Row>
                        {/* End of Exploded Section */}
                    </Col>
                </Row>
            </section>
        </HomeLayout>
    )
}

export default Exploded