import { Col, Row, Card, Container, Button } from "react-bootstrap"

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom"
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import { useWindowSize } from '@react-hook/window-size'

// Global Variable
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-react-data-tools";
import { GridColumn, Grid } from "@progress/kendo-react-grid";
import ExplodedService from "../../services/api/exploded";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from "react-router-dom";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import "./tracking-external.scss"
import TrackingService from "../../services/api/tracking";
import { SwalClose, SwalFire, SwalLoading } from "../../components/SwalHelper";

const MySwal = withReactContent(Swal)

const TrackingExternal = () => {    
    let { ttsid, serial } = useParams();
    const [ isError, setIsError ] = useState(true)
    const [ data, setData ] = useState([])
    const trackingService = new TrackingService()

    useEffect(() => {
        {/* data untuk 1 SN */}
        // if(ttsid === undefined || serial === undefined){
        //     SwalFire('Failed',"Invalid parameter given.","error")
        //     setIsError(true)
        // }else{
        //     SwalLoading()
        //     trackingService.GetStatusBySNTTS([{
        //         "TTSID": ttsid,
        //         "SerialNumber": serial
        //     }]).then(
        //         (res) => {
        //             const df = res.data
                    
        //             if(df.length > 0){
        //                 setData(df[0])
        //                 SwalClose()
        //             }else{
        //                 SwalFire('Failed',"Data not found.","error")
        //                 setIsError(true)
        //             }
        //         }
        //     )
        // }

        {/* data untuk 1 TTS / 1 SN */}
        if(ttsid === undefined){
            SwalFire('Failed',"Invalid parameter given.","error")
            setIsError(true)
        }else{
            SwalLoading()
            console.log('panggil API')
            trackingService.GetStatusBySNTTS([{
                "TTSID": ttsid,
                "SerialNumber": serial === undefined ? '' : serial
            }]).then(
                (res) => {
                    const df = res.data
                    if(df.length > 0){
                        const groupedData = df.reduce((result, item) => {
                            const existingItem = result.find((groupedItem) => groupedItem.SerialNumber === item.SerialNumber);
                          
                            if (existingItem) {
                              existingItem.Parts.push({
                                PartDesc: item.PartDesc,
                                PartPrice: item.PartPrice,
                                PartQty: item.PartQty,
                              });
                            } else {
                              result.push({
                                ...item,
                                Parts: [
                                  {
                                    PartDesc: item.PartDesc,
                                    PartPrice: item.PartPrice,
                                    PartQty: item.PartQty,
                                  },
                                ],
                              });
                            }
                          
                            return result;
                          }, []);
                          {console.log(groupedData)}
                          {console.log('lihat data setelah ubah format data')}
                        // setData(df[0])
                        setData(groupedData)
                        setIsError(false)
                        SwalClose()
                    }else{
                        SwalFire('Failed',"Data not found.","error")
                        setIsError(true)
                    }
                }
            )
        }
    },[])

    // const handleButtonClick = () => {
    //     // SwalLoading()
    //     //     trackingService.submitItemRecieved([{
    //     //         "TTSID": ttsid,
    //     //         "SerialNumber": serial
    //     //     }]).then(
    //     //         (res) => {
    //     //             const df = res.data
                    
    //     //             if(df.length > 0){
    //     //                 setData(df[0])
    //     //                 SwalClose()
    //     //             }else{
    //     //                 SwalFire('Failed',"Data not found.","error")
    //     //                 setIsError(true)
    //     //             }
    //     //         }
    //     //     )
    // };
    // Component start here
    return (
        <Container className="container-tracking">
            {
                isError===false?
                <Row>
                    {/* First Box */}
                    <Row className="row-card">
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row className="container-content">
                                            <Col className="column-text">
                                                <Row>TTS ID</Row>
                                                <Row>Status</Row>
                                                <Row>Status Garansi</Row>
                                                <Row>Tanggal TTS di-cetak</Row>
                                            </Col>
                                            {/* data untuk 1 SN */}
                                            {/* <Col className="value-text align-items-end">
                                                <Row>{data.TTSID}</Row>
                                                <Row>{data.Status}</Row>
                                                <Row>{data.WarrantyStatus}</Row>
                                                <Row>{data.TTSDate?data.TTSDate.split('T')[0]:""}</Row>
                                            </Col>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Customer Name</div>
                                                <div className="value-text p-0">{data.ASCName}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Customer Address</div>
                                                <div className="value-text p-0">{data.ASCAddress}</div>
                                            </Row> */}

                                            {/* data untuk 1 TTS / 1 SN */}
                                            <Col className="value-text align-items-end">
                                                <Row>{data[0].TTSID}</Row>
                                                <Row>{data[0].Status}</Row>
                                                <Row>{data[0].WarrantyStatus}</Row>
                                                <Row>{data[0].TTSDate?data[0].TTSDate.split('T')[0]:""}</Row>
                                            </Col>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Customer Name</div>
                                                <div className="value-text p-0">{data[0].ASCName}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Customer Address</div>
                                                <div className="value-text p-0">{data[0].ASCAddress}</div>
                                            </Row>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* data untuk 1 TTS / 1 SN */}
                    {data.map((item, index) => {
                        return (
                            <>
                            {/* Second Box */}
                            <Row className="row-card">
                                <Col md={6}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row className="container-content">
                                                    <Row className="card-title">Identitas End User</Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">End User Name</div>
                                                        <div className="value-text p-0">{item.NameEU}</div>
                                                    </Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">End User Address</div>
                                                        <div className="value-text p-0">{item.AddressEndUser}</div>
                                                    </Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">No Handphone</div>
                                                        <div className="value-text p-0">{item.HandphoneEU}</div>
                                                    </Row>
                                                </Row>

                                                <Row className="container-content">
                                                    <Row className="card-title">Informasi Servis</Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">SN Produk</div>
                                                        <div className="value-text p-0">{item.SerialNumber}</div>
                                                    </Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">Complaint</div>
                                                        <div className="value-text p-0">{item.Complaint}</div>
                                                    </Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">Ketidaklengkapan</div>
                                                        <div className="value-text p-0">{item.InCompleteness}</div>
                                                    </Row>
                                                    <Row style={{paddingTop:"16px", display: "flex", alignItems: "flex-start" }}>
                                                        <div className="column-text p-0">Harga Part yang Digunakan</div>
                                                        <Col className="column-text">
                                                            {item.Parts.map((itemParts, index) => (
                                                            <Row key={index}>
                                                                <div className="value-text p-0">{itemParts.PartDesc}</div>
                                                                <div className="value-text p-0">Harga  : Rp. {itemParts.PartPrice !== undefined ? itemParts.PartPrice.toLocaleString('id-ID') : ""}</div>
                                                                <div className="value-text p-0">Jumlah : {itemParts.PartQty}</div>
                                                            </Row>
                                                            ))}
                                                        </Col>
                                                        {/* <Col className="column-text">
                                                            {item.Parts.map((itemParts, index) => {
                                                                return (
                                                                <Row>{itemParts.PartDesc}</Row>
                                                                )
                                                            })}
                                                        </Col>
                                                        <Col className="value-text align-items-end">
                                                            {item.Parts.map((itemParts, index) => {
                                                                return (
                                                                <Row>Rp. {itemParts.PartPrice!=undefined?(itemParts.PartPrice).toLocaleString('id-ID'):""}</Row>
                                                                )
                                                            })}
                                                        </Col> */}
                                                    </Row>
                                                    <Row style={{paddingTop:"16px"}}>
                                                        <div className="column-text p-0">Nama Teknisi</div>
                                                        <div className="value-text p-0">{item.CrewName}</div>
                                                    </Row>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            </>
                        )
                        })
                    }

                    {/* data 1 SN */}
                    {/* Second Box */}
                    {/* <Row className="row-card">
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row className="container-content">
                                            <Row className="card-title">Identitas End User</Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">End User Name</div>
                                                <div className="value-text p-0">{data.NameEU}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">End User Address</div>
                                                <div className="value-text p-0">{data.AddressEndUser}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">No Handphone</div>
                                                <div className="value-text p-0">{data.HandphoneEU}</div>
                                            </Row>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    {/* Third Box */}
                    {/* <Row className="row-card">
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <Row className="container-content">
                                            <Row className="card-title">Informasi Servis</Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">SN Produk</div>
                                                <div className="value-text p-0">{data.SerialNumber}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Complaint</div>
                                                <div className="value-text p-0">{data.Complaint}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Ketidaklengkapan</div>
                                                <div className="value-text p-0">{data.InCompleteness}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Harga Part yang Digunakan</div>
                                                <div className="value-text p-0">Rp. {data.PartPrice!=undefined?(data.PartPrice).toLocaleString('id-ID'):""}</div>
                                            </Row>
                                            <Row style={{paddingTop:"16px"}}>
                                                <div className="column-text p-0">Nama Teknisi</div>
                                                <div className="value-text p-0">{data.CrewName}</div>
                                            </Row>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    {/* <Col className="d-flex justify-content-center pt-4 mt-2">
                        <Button id="btn-confirm-item" type="submit" onClick={handleButtonClick}>Barang Diterima</Button>
                    </Col> */}
                </Row>
                :
                <Row className="d-flex flex-column justify-content-center">
                    <h1>404 NOT FOUND</h1>
                    <h5>Please try again...</h5>
                </Row>
            }
        </Container>
    )
}

export default TrackingExternal