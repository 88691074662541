import { useState } from "react"
import NavigationBar from "../../components/NavigationBar"
import NavigationSidebar from "../../components/NavigationSidebar"
import { Col, Container, Form, Row, Button, Alert, InputGroup } from "react-bootstrap"
import { FaUserCog } from "react-icons/fa"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { useFormik } from "formik"
import AuthService from "../../services/api/auth"
import { useDispatch, useSelector } from "react-redux"
import { logOut, selectCurrentUser } from "../../services/redux/AuthSlice"
import HomeLayout from "../_layout/HomeLayout"
import { SwalConfirmation, SwalFire, SwalLoading } from "../../components/SwalHelper"
import { useNavigate, useParams } from "react-router-dom"

// Formik Validation
const validate = (values) => {
    const errors = {}

    if(!values.new_password){
        errors.new_password = 'Please input your new password'
    }else{
        if(!values.confirm_password){
            errors.confirm_password = 'Please confirm your password'
        }else{
            if(values.confirm_password !== values.new_password){
                errors.confirm_password = 'The password confirmation does not match'
            }
        }
    }

    


    return errors
}

const ResetEmail = () => {
    // Param
    let { token } = useParams()

    const authService = new AuthService()
    const user = useSelector(selectCurrentUser)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [sidebarExpanded, setSidebarExpanded] = useState(true)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [reqState, setReqState] = useState("none")

    // Formik Config
    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        onSubmit: (values, actions) => {
            //Submit Form Handler
            // alert(JSON.stringify(values, null, 2))

            SwalConfirmation({
                text:"Pastikan anda sudah mengingat pergantian password.",
                onConfirm: () => {
                    SwalLoading()
                    // Disable Submit Button
                    document.querySelector('#btn-change-password').disabled = true
                    document.querySelector('#btn-change-password').innerHTML = "Updating..."
                    
                    try {
                        authService.ChangePasswordByToken([{
                            'Token': token,
                            'Password': values.new_password,
                            'Password2': values.confirm_password
                        }]).then((res)=>{
                            const ReturnMessage = res.data[0].ReturnMessage

                            setReqState(ReturnMessage)
                            if(ReturnMessage==="Success"){
                                actions.resetForm()
                                SwalFire("Success","Berhasil mengganti password, silahkan Login ulang.",'success',() => {})
                                dispatch(logOut())
                                navigate('/')
                            }else{
                                SwalFire("Failed",ReturnMessage+"<br>Please, try again...",'error')
                            }

                            document.querySelector('#btn-change-password').disabled = false
                            document.querySelector('#btn-change-password').innerHTML = "Save"
                        })
                    } catch (error) {
                        SwalFire("Failed",JSON.stringify(error, null, 2),'error')
                        document.querySelector('#btn-change-password').disabled = false
                        document.querySelector('#btn-change-password').innerHTML = "Save"
                    }
                }
            })
        },
        validate
    })

    return(
        <section className="page-reset">
            <header className="text-center">
                <NavigationBar authorized={false}/>
            </header>
            <main className="pt-5">
                <Container className="pt-5">
                    <section id="page-change-password" className="pt-5">
                        <Row className="my-4">
                            <Col className="d-inline-flex">
                                <FaUserCog size={70}/>
                                <div className="px-3">
                                <h1 className="mb-0">Account</h1>
                                <div style={{
                                    color: "gray",
                                    paddingLeft: 3
                                }}>
                                    Change Password
                                </div>
                                </div>
                            </Col>
                        </Row>

                        <hr/>

                        {/* Form Section to change password */}
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    New Password
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showNewPassword?'text':'password'}
                                        placeholder='Enter your new password'
                                        name='new_password'
                                        id='new_password'
                                        value={formik.values.new_password}
                                        onChange={formik.handleChange}
                                    />
                                    <Button variant="outline-secondary" type="button" onClick={() => setShowNewPassword(!showNewPassword)}>
                                        {
                                            showNewPassword
                                            ?<VscEye/>
                                            :<VscEyeClosed/>
                                        }
                                    </Button>
                                </InputGroup>
                                {formik.errors.new_password && formik.touched.new_password ? (
                                    <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.new_password}</div>
                                ):null}
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    Confirm Password
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={showConfirmPassword?'text':'password'}
                                        placeholder='Enter your new password'
                                        name='confirm_password'
                                        id='confirm_password'
                                        value={formik.values.confirm_password}
                                        onChange={formik.handleChange}
                                    />
                                    <Button variant="outline-secondary" type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {
                                            showConfirmPassword
                                            ?<VscEye/>
                                            :<VscEyeClosed/>
                                        }
                                    </Button>
                                </InputGroup>
                                {formik.errors.confirm_password && formik.touched.confirm_password ? (
                                    <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.confirm_password}</div>
                                ):null}
                            </Form.Group>
                            
                            <Row>
                                <Col>
                                    <Button id="btn-change-password" type="submit">Save</Button>
                                </Col>
                            </Row>

                            {reqState === "Success" ? (
                                <div className="p-1 my-1 text-success">Your new password have been saved</div>
                            ):reqState!=="none" && <div className="p-1 my-1 text-danger">{reqState}</div>}
                        </Form>
                        {/* End form section */}
                    </section>
                </Container>
            </main>
        </section>
    )
}

export default ResetEmail