import React from 'react'
import PropTypes from "prop-types"
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap"
import navbarIcon from "../assets/images/icons/ASC Icon.svg"
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa'
import "./NavigationBar.css"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logOut, selectCurrentUser } from '../services/redux/AuthSlice'

const NavigationBar = (props) => {
    const { authorized } = props

    const user = useSelector(selectCurrentUser)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <section className="component-navigation-bar">
            <Navbar bg='dark' variant='dark'>
                <Container fluid>
                    <Navbar.Brand>
                    <img
                        alt="Application logo"
                        src={navbarIcon}
                        width="30"
                        height="30"
                        className="d-inline-block align-top mx-3"
                    />
                    Cosmos Authorized Service Center
                    </Navbar.Brand>
                    {
                        authorized ? 
                        // <Nav>
                        //     
                        //     <Nav.Link>Account</Nav.Link>
                        //     <Nav.Link>Log Out</Nav.Link>
                        // </Nav>
                        <Nav>
                            <Row>
                                <Col md={"auto"}>
                                    <Nav.Link>
                                        Welcome, {user.Name}
                                    </Nav.Link>
                                </Col>
                                <Col md={"auto"}>
                                    <Nav.Link onClick={()=>navigate('/change-password')}>
                                        <FaUserCircle/> Account
                                    </Nav.Link>
                                </Col>
                                <Col md={"auto"}><Nav.Link onClick={
                                    () => {
                                        dispatch(logOut())
                                        navigate('/')
                                    }
                                }>
                                    <FaSignOutAlt/> Log Out
                                </Nav.Link></Col>
                            </Row>
                        </Nav>
                        : null
                    }
                </Container>
            </Navbar>
        </section>
    )
}

Navbar.propTypes = {
    authorized : PropTypes.bool
}

Navbar.defaultProps = {
    authorized : true
}

export default NavigationBar