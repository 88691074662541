import axios from "axios";
import Cookies from "universal-cookie";
import _GlobalModel from "./_GlobalURL";

export default class Api {
    constructor(mode) {
      this.api_token = null;
      this.client = null;

      switch (mode) {
        case "asc":
          this.api_url = _GlobalModel.urlASC
          break;
        case "apiUrl":
          this.api_url = _GlobalModel.apiURL
          break;
        default:
          break;
      }
      
    }
  
    init = () => {
      const cookies = new Cookies()
      this.api_token = cookies.get("ACCESS_TOKEN");
  
      let headers = {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Referrer-Policy": "strict-origin-when-cross-origin"
      };
  
      if (this.api_token) {
        headers.Authorization = `Bearer ${this.api_token}`;
      }
  
      this.client = axios.create({
        baseURL: this.api_url,
        timeout: 31000,
        headers: headers,
      });
  
      return this.client;
    };
}