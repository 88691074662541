import Api from "./_base"

export default class TrackingService {
    constructor(){
        this.api = new Api("apiUrl")
    }

    GetStatusBySNTTS = (params) => {
        const encodedParams = JSON.stringify(params)
        
        return this.api.init().post("", {
            i:'BBAA7821-E19A-4399-BFA2-B1FEF92FD212',
            d:encodedParams
        });
    }

    // submitItemRecieved = (params) => {
    //     const encodedParams = JSON.stringify(params)
        
    //     return this.api.init().post("", {
    //         i:'BBAA7821-E19A-4399-BFA2-B1FEF92FD212',
    //         d:encodedParams
    //     });
    // }
}