import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):null,
        isLoggedIn: sessionStorage.getItem("user")?true:false,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { resUser } = action.payload
            sessionStorage.setItem('user',JSON.stringify(resUser))
            state.user = resUser
            state.isLoggedIn = true
        },
        logOut: (state, action) => {
            state.user = null
            sessionStorage.removeItem('user')
            state.isLoggedIn = false
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions
export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectLoginState = (state) => state.auth.isLoggedIn