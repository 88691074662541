import React, { useState } from "react";
import PropTypes from "prop-types"
// import NavigationSidebar from "../../components/NavigationSidebar";
import NavigationSidebarConstant from "../../components/NavigationSidebarConstant";

const HomeLayout = ({children, activeSidebar}) => {
    const [sidebarExpanded, setSidebarExpanded] = useState(true)
    
    return (
        <React.Fragment>
            <section className="home-layout">
                {/* Template */}
                <div>
                    <NavigationSidebarConstant activeMenu={activeSidebar} toggleMenu={() => {setSidebarExpanded(!sidebarExpanded)}}/>
                    <main className={sidebarExpanded?"with-sidebar pt-3 px-4":"with-sidebar with-sidebar-NX  pt-3 px-4"}>
                        {/* Content Here */}
                        {children}
                        {/* End Content Here */}
                    </main>
                </div>
                {/* Template */}
            </section>
        </React.Fragment>
    );
}

HomeLayout.propTypes = {
    activeSidebar : PropTypes.string,
}

HomeLayout.defaultProps = {
    activeSidebar : "None"
}

export default HomeLayout