import { Col, Row, Container } from "react-bootstrap"
import { HiHomeModern } from "react-icons/hi2"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../services/redux/AuthSlice"
import HomeLayout from "../_layout/HomeLayout"

const Dashboard = () => {
    const user = useSelector(selectCurrentUser)
    return (
        <HomeLayout activeSidebar="Home">
            <section className="page-dashboard my-4">
                <Row className="mb-5">
                    <Col className="d-inline-flex">
                        <HiHomeModern size={70}/>
                        <div className="px-3">
                            <h1 className="mb-0">Dashboard</h1>
                            <div style={{
                                color: "gray",
                                paddingLeft: 3
                            }}>
                                Homepage
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <Container fluid>
                    <Row>
                        <h1>Welcome to Authorized Service Center</h1>
                        <div style={{
                            color: "gray",
                        }}>
                            Mr./Ms./Mrs. {user.Name}
                        </div>
                    </Row>
                </Container>
            </section>
        </HomeLayout>
    )
}

export default Dashboard