import { Button, Nav } from "react-bootstrap"
//import "./NavigationSidebar.css"

// Dummy Icon
// import SidebarIcon from '../assets/images/icons/navbar-icon.png'
// import { FaEquals } from 'react-icons/fa'
import { useState } from "react"
import { FcHome, FcInspection } from "react-icons/fc";
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom";
import { GiHypodermicTest } from "react-icons/gi"

const NavigationSidebar = (props) => {
    const { activeMenu, toggleMenu } = props

    const navigate = useNavigate()
    
    const [isExpanded, setExpended] = useState(true)
    const [active, setActive] = useState(activeMenu)

    const menuItems = [
        {
            text: "Home",
            icon: <FcHome size={30}/>,
            link: "/home"
        },
        {
            text: "Report",
            icon: <FcInspection size={30}/>,
            link: "/report"
        },
    ]

    return(
        <section className="component-navigation-sidebar">
            <div className={isExpanded?"side-nav-container text-dark":"side-nav-container side-nav-container-NX text-dark"}>
                <div className="nav-upper">
                    <div className="nav-header">
                        {/* <div className="nav-brand">
                                <img className="" src={SidebarIcon} alt="Sidebar Icon" />
                            <h3>Cosmos ASC</h3>
                        </div> */}
                        <Button className={
                                isExpanded
                                ?"nav-toggle nav-toggle-in bg-secondary border border-2 rounded-circle"
                                :"nav-toggle nav-toggle-out bg-secondary border border-2 rounded-circle"
                            }
                            onClick={() => {
                                setExpended(!isExpanded)
                                toggleMenu()
                            }}
                        >
                            <span className="bg-light"></span>
                            <span className="bg-light mt-1"></span>
                            <span className="bg-light mt-1"></span>
                        </Button>
                    </div>
                    <div className="nav-body">
                        <Nav className="justify-content-center">
                            {
                                menuItems.map(({text, icon, link}) => {
                                    return (
                                        <div className={active===text?"nav-menu-active rounded":"nav-menu-passive"}>
                                            <Nav.Link onClick={()=>navigate(link)} className={isExpanded
                                            ?"nav-body-menu my-1 rounded"
                                            :"nav-body-menu nav-body-menu-NX my-1 rounded"}>
                                                <div className="nav-menu-icon">
                                                    {icon}
                                                </div>
                                                {
                                                    isExpanded && 
                                                    <div className={active===text
                                                        ?"nav-menu-text d-inline-block px-5 nav-menu-text-active"
                                                        :"nav-menu-text d-inline-block px-5 text-dark"
                                                    }>
                                                        {text}
                                                    </div>
                                                }
                                            </Nav.Link>
                                        </div>
                                    )
                                })
                            }
                        </Nav>
                    </div>
                </div>
            </div>
        </section>
    )
}

NavigationSidebar.propTypes = {
    activeMenu : PropTypes.string,
    toggleMenu : PropTypes.func.isRequired
}

NavigationSidebar.defaultProps = {
    activeMenu : "None"
}

export default NavigationSidebar