import { Col, Row, Card, Container } from "react-bootstrap"

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom"
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import { useWindowSize } from '@react-hook/window-size'

// Global Variable
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { orderBy } from "@progress/kendo-react-data-tools";
import { GridColumn, Grid } from "@progress/kendo-react-grid";
import ExplodedService from "../../services/api/exploded";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from "react-router-dom";
import ReactPanZoom from "react-image-pan-zoom-rotate";

const MySwal = withReactContent(Swal)

const ExplodedExternal = () => {    
    let { material_id, bom, alternative } = useParams();

    const explodedService = new ExplodedService()
    const [width, height] = useWindowSize({initialWidth: 400, initialHeight: 400})
    
    // Viewer Variables
    const [svg, setSvg] = useState(null)
    const [png, setPng] = useState(null)
    const Viewer = useRef(null)
    const [viewerState, setViewerState] = useState(false)
    const [interactiveState, setInteractiveState] = useState(true)
    const [header, setHeader] = useState(null)
    const [displayState, setDisplayState] = useState(null)

    // Kendo Utility
    const [partData, setPartData] = useState(null)
    const [filename, setFilename] = useState(null)
    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(10)
    const _export = useRef(null)
    const _grid = useRef(null)

    const onPageChange = (e) => {
        setSkip(e.page.skip)
        setTake(e.page.take)
    }

    const initialSort = [{
        field: 'ASCName',
        dir: 'asc'
    }]

    const [sort, setSort] = useState(initialSort)

    const kendoRowRender = (trElement, props) => {
        const checkId = /^\d+$/.test(props.dataItem.line)
        const trProps = {
            className: checkId ? 'part-parent' : 'part-child',
            id: 'KendoRow'+props.dataItem.line
        };
        return React.cloneElement(
            trElement,
            {
            ...trProps,
            },
            trElement.props.children
        );

    }

    //To Check Viewer
    useEffect(() => {
        if(!viewerState && Viewer.current){
            Viewer.current.fitToViewer()
            setViewerState(true)
        }
        
        if(!interactiveState && partData != null && svg != null){
            setupInteractiveUtils()
        }
    })

    //Constructor
    useEffect(() => {
        // Initialize Family Product
        fetchHeader(material_id, bom, alternative)
    },[])

    // on QUERY VIEWER
    // [1] Get Header
    const fetchHeader = (material_id, bom, alternative) => {
        MySwal.fire({
            title: <p>Loading data...</p>,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showConfirmButton: false,
            didOpen: () => {
              MySwal.showLoading()
            },
        })
        // Fetch Header
        explodedService.GetHeader(material_id, bom, alternative).then((res) => {
            const data = res.data[0]
            if(data.url_external !== ""){                
                setHeader({
                    maktx: data.maktx,
                    matnr: data.matnr,
                })
    
                // Continue to setup SVG Viewer
                fetchDetail(material_id,bom,alternative)

                // SVG PNG VIEWER
                const fileType = data.url_external.split('.')[data.url_external.split('.').length-1]

                if(fileType.toLowerCase() === "svg"){
                    setupExplodedSvg(data.url_external)
                }else{
                    setupExplodedPng(data.url_external)
                }

                // Display Viewer Container
                setDisplayState("founded")
            }else{
                // Display Unfounded Warning
                setDisplayState("notfound")
                MySwal.close()
            }
        })
    }

    // [2.1] SVG Setup
    const setupExplodedSvg = async (url) => {
        setViewerState(false)
        axios.get(url).then((res) => {
            let frag = document.createRange().createContextualFragment(res.data)
            console.log(frag.querySelector('svg').getAttribute('viewBox').split(' ')[3]," : ",frag.querySelector('svg').getAttribute('viewBox').split(' ')[2])
            setSvg({
                source: url,
                height: frag.querySelector('svg').getAttribute('viewBox').split(' ')[3],
                width: frag.querySelector('svg').getAttribute('viewBox').split(' ')[2],
            },[])
        })
    }

    // [2.2] PNG Setup
    const setupExplodedPng = async (url) => {
        setViewerState(false)
        setPng(url)
        MySwal.close()
    }

    

    // [3] Get Detail
    const fetchDetail = async (material_id, bom, alternative) => {
        // Fetch Detail
        explodedService.GetDetail(material_id, bom, alternative).then((res) => {
            const data = res.data

            // Initialize kendo
            setSort(initialSort)
            setFilename("EXPLODED_"+material_id+"-"+bom+"-"+alternative)
            setPartData(data)

            // Initialize Interactive Utils
            setInteractiveState(false)
        })
    }

    // [4] Setup Interactive Util
    const setupInteractiveUtils = () => {
        setTimeout(() => {
            // Setup SVG ViewBox
            console.log(document.getElementsByClassName('svg-content')[0].querySelectorAll('svg')[1].getAttribute('height'))
            document.getElementsByClassName('svg-content')[0].querySelectorAll('svg')[1].setAttribute('width',svg.width)
            document.getElementsByClassName('svg-content')[0].querySelectorAll('svg')[1].setAttribute('height',svg.height)
            
            partData.forEach((part) => {
                const partId = part.line
                const checkId = /^\d+$/.test(partId)
                
                if(checkId){
                    const partItem = document.querySelector('g#Partsitem'+partId)
                    const partNumber = document.querySelector('g#No'+partId)
    
                    if(partItem != null && partNumber != null){
                        // ON ENTER SVG
                        partItem.addEventListener('mouseenter', (e) => {
                            e.stopPropagation()
                            document.querySelectorAll('.part-parent').forEach((e) => {
                                e.classList.remove('bg-success')
                            })
                            document.querySelector('#KendoRow'+partId).classList.add('bg-success')
                            selectKendoGridRows(partId)
                        })
    
                        partNumber.addEventListener('mouseenter', (e) => {
                            e.stopPropagation()
                            document.querySelectorAll('.part-parent').forEach((e) => {
                                e.classList.remove('bg-success')
                            })
                            document.querySelector('#KendoRow'+partId).classList.add('bg-success')
                            selectKendoGridRows(partId)
                        })
    
                        //ON ENTER TABLE ROW
                        document.querySelector('#KendoRow'+partId).addEventListener('mouseenter', () => {
                            Viewer.current.fitToViewer()
                            const bbox = document.getElementById('Partsitem'+partId).getBBox()
                            Viewer.current.fitSelection(bbox.x-20, bbox.y-20, bbox.width+50, bbox.height+30, 0)

                            // Colorize SVG PART
                            document.querySelectorAll('.part-vector').forEach((e) => {
                                e.setAttribute('stroke','black')
                            });
                            
                            let divChild = document.getElementById('Partsitem'+partId).children
                            for (let i = 0; i < divChild.length; i++) {
                                const e = divChild[i];
                                e.setAttribute('stroke','green')
                            }
                        })
    
                        //Setup path id
                        let divChild = document.getElementById('Partsitem'+partId).children
                        for (let i = 0; i < divChild.length; i++) {
                            const e = divChild[i];
                            e.classList.add('part-vector')
                            e.classList.add('part-vector-'+partId)
                        }
                    }
                }
            });
            setInteractiveState(true)
            MySwal.close()
        }, 1000);
    }

    // [4.1] Kendo Row Selected
    const selectKendoGridRows = (id) => {
        // Scroll to a row
        var tableContainer = document.querySelector('.k-grid-content'),
            selectedRow = document.querySelector('#KendoRow'+id)

        selectedRow.scrollIntoView()

        // Colorize SVG PART
        document.querySelectorAll('.part-vector').forEach((e) => {
            e.setAttribute('stroke','black')
        });
        
        let divChild = document.getElementById('Partsitem'+id).children
        for (let i = 0; i < divChild.length; i++) {
            const e = divChild[i];
            e.setAttribute('stroke','green')
        }

        // Colorize Path
        // $('.part-vector').attr('stroke','black')

        // $('#Partsitem'+id).children().attr('stroke','green')
    }

    // Component start here
    return (
        <Container fluid>
            <section className="page-exploded">
                <Row>
                    <Col>
                        {/* Not Initialized Section */}
                        <Row className="justify-content-center" hidden={displayState==="notfound"?false:true}>
                            <Col sm={6} className="text-center">
                                <Card className="text-center">
                                    <Card.Header>Load Failed</Card.Header>
                                    <Card.Body>
                                        <Card.Title>WE ARE SORRY!</Card.Title>
                                        <Card.Text>
                                            Exploded view for this version is not initialied yet, please try another version.
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">Contact us if something went wrong</Card.Footer>
                                </Card>
                            </Col>
                        </Row>

                        {/* Exploded Section */}
                        <Row className="mt-2" hidden={displayState==="founded"?false:true}>
                            {/* SVG Section */}
                            <Col sm={8}>
                                {/* Header */}
                                {
                                    header != null
                                    ?<Row>
                                        <Col className="h3 w-100" style={{fontWeight: "bold", fontSize:"3vw"}}>
                                            {header.maktx}
                                        </Col>
                                        <Col className="d-flex justify-content-end h3 text-secondary" style={{fontSize:"3vw"}}>
                                            {header.matnr}
                                        </Col>
                                    </Row>
                                    :null
                                }
                                <Row className="border border-2 border-dark rounded" style={{
                                    position:"relative",
                                    overflow: "hidden",
                                    height:"85%"
                                }}>
                                    {/* SVG Viewer */}
                                    {
                                        svg==null
                                        ?null
                                        :<ReactSvgPanZoomLoader src={svg.source} render={(content) => {
                                            return (
                                                (
                                                    <UncontrolledReactSVGPanZoom
                                                        ref={Viewer}
                                                        height={height*0.85}
                                                        width={width*0.66}
                                                        className="svg-content"
                                                    >
                                                        <svg
                                                            width={svg.width}
                                                            height={svg.height}
                                                        >
                                                        {content}
                                                        </svg>
                                                    </UncontrolledReactSVGPanZoom>
                                                )
                                            )
                                        }} />
                                    }

                                    {/* Simple Image Viewer */}
                                    {
                                        png==null
                                        ?null
                                        :<ReactPanZoom
                                            style={{
                                                
                                            }}
                                            image={png}
                                            alt="Exploded component image."
                                        />
                                    }
                                    
                                </Row>
                            </Col>
                            {/* End of SVG Section */}

                            {/* Detail Section */}
                            <Col className="" sm={4}>
                                <Row className="h3">
                                    <center>Part Detail</center>
                                </Row>

                                {/* Kendo Container */}
                                <Row>
                                    <Col id="container-kendo-grid">
                                        {   partData!=null && filename!=null &&
                                            <section>
                                                <div className="overflow-auto my-2 rounded" style={{
                                                    height: "82vh",
                                                }}>
                                                    <ExcelExport ref={_export} fileName={filename}>
                                                        <Grid 
                                                            className="mh-50 overflow-auto"
                                                            rowRender={kendoRowRender}
                                                            ref={_grid}
                                                            data={orderBy(partData, sort)}
                                                            total={partData.length}
                                                            scrollable={true}   
                                                            pageable={false}
                                                            sortable={true}
                                                            sort={sort}
                                                            onSortChange={e => {
                                                                setSort(e.sort)
                                                            }}
                                                            reorderable={true}
                                                            resizable={true}         
                                                            groupable={false}  

                                                            // IN CASE NEED PAGINATION
                                                            skip={skip}
                                                            take={take}
                                                            onPageChange={onPageChange}
                                                        >
                                                            <GridColumn
                                                                title= "No. "  dataType= "string"  field= "line"  width={50}  editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Material No."  dataType= "string"  field= "idnrk"  width={120}  editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Description"  dataType= "string"  field= "maktx" minResizableWidth={300} width={300} editor= {false}  editable= {true}
                                                            />
                                                            <GridColumn
                                                                title= "Qty"  dataType= "string"  field= "menge" minResizableWidth={80} width={80} editor= {false}  editable= {true}
                                                            />
                                                        </Grid>
                                                    </ExcelExport>
                                                </div>
                                            </section>
                                        }
                                    </Col>
                                </Row>
                                {/* End of Kendo Container */}

                            </Col>
                            {/* End of Detail Section */}
                        </Row>
                        {/* End of Exploded Section */}
                    </Col>
                </Row>
            </section>
        </Container>
    )
}

export default ExplodedExternal