import React, {  useEffect, useRef, useState } from "react"
import { Col, Container, Form, Row, Button } from "react-bootstrap"
import { TbReportSearch } from "react-icons/tb"
import { MultiCascader, DateRangePicker, CheckPicker, Checkbox } from "rsuite"
import ReportService from "../../services/api/report"
import { isEmptyArray, useFormik } from "formik"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../services/redux/AuthSlice"
import { Grid, GridColumn } from "@progress/kendo-react-grid"
import { ExcelExport } from "@progress/kendo-react-excel-export"

// Kendo CSS
import '@progress/kendo-theme-default/dist/all.css'
import { orderBy } from "@progress/kendo-react-data-tools"
import HomeLayout from "../_layout/HomeLayout"
import moment from "moment"
import { process } from "@progress/kendo-data-query"
import { SwalClose, SwalFire, SwalLoading } from "../../components/SwalHelper"

// Date Range Picker
const { allowedMaxDays } =
  DateRangePicker;

// Formik Validation
const validate = (values) => {
    const errors = {}

    if(!values.period){
        errors.period = 'Please pick a period date'
    }

    if(isEmptyArray(values.email)){
        errors.email = 'Please choose one or more user'
    }

    if(isEmptyArray(values.customer)){
        errors.customer = 'Please choose one or more customer'
    }

    return errors
}

const Report = () => {
    // Services
    const reportService = new ReportService()
    const user = useSelector(selectCurrentUser)
    // Component States
    const [emailData, setEmailData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [reportData, setReportData] = useState(null)

    // [ Kendo Utility ]
    // [1] Config
    const [filename, setFilename] = useState(null)
    const _grid = useRef(null)
    const initialDataState = {
        sort: [
          {
            field: "ASCName",
            dir: "asc",
          },
        ],
        take: 10,
        skip: 0,
    };

    const [dataState, setDataState] = useState(initialDataState)

    // [4] Excel
    const _export = useRef(null)

    //Formik Validator
    const formik = useFormik({
        initialValues: {
            period:"",
            email:[],
            customer:[],
        },
        onSubmit: (values) => {
            SwalLoading("Generating Report")

            //Submit Form Handler
            const pst = moment(values.period[0]).format('yyyy-MM-DD')
            const pnd = moment(values.period[1]).format('yyyy-MM-DD')
            const email = values.email.join(',')
            const customer = values.customer.join(',')

            // Disable Submit Button
            document.querySelector('#btn-view-report').disabled = true
            document.querySelector('#btn-view-report').innerHTML = "Generating..."

            try {
                reportService.GenerateReport([{
                    'ASCID':user.ASCID,
                    'Email':email,
                    'CustomerID':customer,
                    'DateFrom':pst,
                    'DateTo':pnd,
                    'User':user.Name
                    // Hard Code Testing
                    // 'p1':"",
                    // 'p2':"",
                    // 'p3':"",
                    // 'p4':"2016/01/01",
                    // 'p5':"2023/01/01",
                    // 'p6':"jimmy.rianto"
                }]).then(
                    (res) => {
                        const df = res.data
                        const timestamp = new Date().toUTCString()

                        setFilename("ReportCASCQ_"+timestamp)
                        setReportData(df)
    
                        document.querySelector('#btn-view-report').disabled = false
                        document.querySelector('#btn-view-report').innerHTML = "View Report"

                        SwalClose()
                    }
                )
            } catch (error) {
                SwalFire("Failed",JSON.stringify(error, null, 2),"error")
                document.querySelector('#btn-view-report').disabled = false
                document.querySelector('#btn-view-report').innerHTML = "View Report"
            }
        },
        validate
    })

    // Initiate Component Value State
    useEffect(() => {
        reportService.GetAllEmail([{
            'ASCID':user.ASCID,
            'User':user.Email,
        }]).then(
            (res) => {
                let children = []
                let allEmail = []
                
                res.data.map((item) => {
                    children.push({
                        label: item.Email,
                        value: item.Email
                    })

                    allEmail.push(item.Email)
                })

                setEmailData(children)

                // setEmailData( [{
                //     label: "All Email",
                //     value: allEmail.join(','),
                //     children: children
                // }])
            }
        )

        reportService.GetAllCustomer([{
            'ASCID':user.ASCID,
            'User':user.Name
        }]).then(
            (res) => {
                let children = []
                let allCustomer = []

                res.data.map((item) => {
                    children.push({
                        label: item.CustomerName,
                        value: item.CustomerID
                    })

                    allCustomer.push(item.CustomerID)
                })

                setCustomerData(children)

                // setCustomerData([{
                //     label: "All Customer",
                //     value: allCustomer.join(','),
                //     children: children
                // }])
            }
        )
    },[])

    return (
        <HomeLayout activeSidebar="Report ASC Visit">
            <section className="page-report my-4">
                <Row className="mb-5">
                    <Col className="d-inline-flex">
                        <TbReportSearch size={70}/>
                        <div>
                        <h1 className="mb-0">Report</h1>
                        <div style={{
                            color: "gray",
                            paddingLeft: 3
                        }}>
                            ASC Visit
                        </div>
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        {/* Form section to query report */}
                        <Form className="" onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col md={3}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Periode
                                        </Form.Label>
                                        <DateRangePicker
                                            className="w-100"
                                            defaultCalendarValue={[new Date(), new Date()]}
                                            onChange={(val) => {
                                                formik.setFieldValue('period',val)
                                            }}
                                            name="period"
                                            showOneCalendar
                                            disabledDate={allowedMaxDays(60)}
                                        />
                                        {formik.errors.period && formik.touched.period ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.period}</div>
                                        ):null}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            User Apps ASC
                                        </Form.Label>
                                        <br/>
                                        <CheckPicker 
                                            block
                                            className="w-100 mw-100"
                                            name="email"
                                            data={emailData}
                                            // appearance="subtle"
                                            placeholder="Select one or more user"
                                            menuWidth={250}
                                            value={formik.values.email}
                                            onChange={(val) => {
                                                formik.setFieldValue('email',val)
                                            }}
                                            renderExtraFooter={() => (
                                                <div style={{
                                                    padding: '10px 2px',
                                                    borderTop: '1px solid #e5e5e5'
                                                }}>
                                                    <Checkbox
                                                        indeterminate={formik.values.email.length > 0 && formik.values.email.length < emailData.length}
                                                        checked={formik.values.email.length === emailData.length}
                                                        onChange={(value, checked) => {
                                                            formik.setFieldValue('email', checked ? emailData.map(email => email.value) : [])
                                                        }}
                                                    >
                                                        Select All
                                                    </Checkbox>
                                                </div>
                                            )}
                                        />
                                        {formik.errors.email && formik.touched.email ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.email}</div>
                                        ):null}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            Customer
                                        </Form.Label>
                                        <br/>
                                        <CheckPicker 
                                            block
                                            className="w-100 mw-100"
                                            name="customer"
                                            data={customerData}
                                            value={formik.values.customer}
                                            // appearance="subtle"
                                            placeholder="Select one or more customer"
                                            menuWidth={250}
                                            onChange={(val) => {
                                                formik.setFieldValue('customer',val)
                                            }}
                                            renderExtraFooter={() => (
                                                <div style={{
                                                    padding: '10px 2px',
                                                    borderTop: '1px solid #e5e5e5'
                                                }}>
                                                    <Checkbox
                                                        indeterminate={formik.values.customer.length > 0 && formik.values.customer.length < customerData.length}
                                                        checked={formik.values.customer.length === customerData.length}
                                                        onChange={(value, checked) => {
                                                            formik.setFieldValue('customer', checked ? customerData.map(customer => customer.value) : [])
                                                        }}
                                                    >
                                                        Select All
                                                    </Checkbox>
                                                </div>
                                            )}
                                        />
                                        {formik.errors.customer && formik.touched.customer ? (
                                            <div className="p-1 my-1 text-danger" variant='danger'>{formik.errors.customer}</div>
                                        ):null}
                                        
                                    </Form.Group>
                                </Col>
                                <Col className="pt-4 mt-2">
                                    <Button id="btn-view-report" type="submit">View Report</Button>
                                </Col>
                            </Row>
                        </Form>
                        {/* End of Form section */}
                    </Col>
                </Row>

                <hr/>

                {/* Container for report result */}
                <Row>
                    <Col id="container-kendo-grid">
                        {   reportData!=null && filename!=null &&
                            <section>
                                <Row className="">
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="success" onClick={() => {
                                            if (_export.current !== null) {
                                                
                                                _export.current.save(process(reportData, {...dataState, skip:0, take:reportData.length}), _grid.current.columns);
                                            }
                                        }}>
                                            Export To Excel
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="overflow-auto my-2" style={{
                                    // height: 500,
                                }}>
                                    <ExcelExport ref={_export} fileName={filename}>
                                        <Grid 
                                            ref={_grid}
                                            scrollable={true}   
                                            pageable={true}
                                            filterable={true}
                                            sortable={true}
                                            reorderable={true}
                                            resizable={true}
                                            data={process(reportData, dataState)}
                                            {...dataState}

                                            onDataStateChange={(e) => {
                                                console.log(e)
                                                setDataState(e.dataState);
                                            }}
                                        >
                                            <GridColumn
                                                title= "ASC Name"  dataType= "string"  field= "ASCName"  width={200}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Vendor ID"  dataType= "string"  field= "VendorID"  width= {120}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Employee Name"  dataType= "string"  field= "PersonelName"  width={200}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Employee ID"  dataType= "string"  field= "CreatedBy"  width={200}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Tanggal Kunjungan"  filter="date" dataType= "string"  field= "Date"  width={100}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Sales Office"  dataType= "string"  field= "SalesOffice"  width= {100}  editor= {false}  editable= {true}
                                            /> 
                                        <GridColumn
                                                title= "ID Customer"  dataType= "string"  field= "CustomerID"  width={130}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Nama Toko"  dataType= "string"  field= "CustomerName"  width={200}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Alamat Toko"  dataType= "string"  field= "CustomerAddress"  width={250}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Kota"  dataType= "string"  field= "Kota"  width={250}  editor= {false}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Check In"  dataType= "string"  field= "DateIn"  editor= {false}  width={100}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Check Out"  dataType= "string"  field= "DateOut"  editor= {false}  width={100}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Duration(Min)"  dataType= "string"  field= "DurationInMnt"  editor= {false}  width={150}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Laporan"  dataType= "string"  field= "Notes"  editor= {false}  width={200}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Distance In"  dataType= "string"  field= "distanceIn"  editor= {false}  width={250}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Distance Out"  dataType= "string"  field= "distanceOut"  editor= {false}  width={250}  editable= {true}
                                            /> 
                                            <GridColumn
                                                title= "Status"  dataType= "string"  field= "Status"  editor= {false}  width={200}  editable= {true}
                                            />
                                        </Grid>
                                    </ExcelExport>
                                </div>
                            </section>
                        }
                    </Col>
                </Row>
                {/* End of Container Section */}
            </section>
        </HomeLayout>
    )
}

export default Report