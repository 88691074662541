import { useEffect, useRef, useState } from "react"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import NavigationBar from "../../components/NavigationBar"
import AuthService from "../../services/api/auth"
import { setCredentials } from "../../services/redux/AuthSlice"
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import logocosmos from "../../assets/images/icons/logocosmos.png"
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const Login = () => {
    // API
    const authService = new AuthService()

    // Config
    const userRef = useRef()
    const errRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)

    // User Data
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        userRef.current.focus()
    },[])

    useEffect(() => {
        setErrMsg('')
    },[user, pwd])

    // Handling Login
    const handleSubmit = async(e) => {
        e.preventDefault()

        MySwal.fire({
            title: <p>Loading data...</p>,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCloseButton: false,
            showConfirmButton: false,
            didOpen: () => {
              MySwal.showLoading()
            },
        })

        authService.Authenticate([{
            'Email':user,
            'Password':pwd,
        }])
        .then((res) => {
            const ReturnMessage = res.data[0].ReturnValue
            const resUser = res.data[0]

            if(ReturnMessage === "Success"){
                dispatch(setCredentials({ resUser }))
                setUser('')
                setPwd('')

                MySwal.close()
                navigate('/home')
            }else{
                MySwal.close()
                setErrMsg(ReturnMessage)
            }
        }).catch((err) => {
            if (!err?.originalStatus) {
                setErrMsg('No Server Response');
            } else if (err.originalStatus === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.originalStatus === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus()
            MySwal.close()
        })
    }

    return (
        <section className="page-login">
            <main>
                <Container>
                    <Row className="d-flex align-items-center justify-content-center" style={{
                        height:"100vh",
                    }}>
                        <Col lg={6}>
                            <Card className="border-0">
                                <Card.Body>
                                    <div className="text-center p-5">
                                        <Row className="d-flex justify-content-center">
                                            <Col>
                                                <img src={logocosmos} className="img-fluid" alt="Cosmos Logo"></img>
                                            </Col>
                                        </Row>
                                        <div className="h4 font-weight-bold">Authorized Service Center | Development</div>
                                    </div>
                                    <Form onSubmit={handleSubmit} autoComplete="on  ">
                                        <Alert variant="danger" ref={errRef} className={errMsg?"":"d-none"}>{errMsg}</Alert>
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control
                                                className="p-3"
                                                type='text'
                                                placeholder='Enter your username/email...'
                                                name='username'
                                                id='username'
                                                ref={userRef}
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Password</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    className="p-3"
                                                    type={showPassword?'text':'password'}
                                                    placeholder='Enter your password'
                                                    name='password'
                                                    id='password'
                                                    onChange={(e) => setPwd(e.target.value)}
                                                    value={pwd}
                                                    required
                                                />
                                                <Button className="px-3" variant="outline-secondary" type="button" onClick={() => setShowPassword(!showPassword)}>
                                                    {
                                                        showPassword
                                                        ?<VscEye/>
                                                        :<VscEyeClosed/>
                                                    }
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>

                                        <Row className="text-center">
                                            <div className="mb-3">
                                                <a href='#' onClick={()=>navigate('/reset-password')}>Forgot password ?</a>
                                            </div>
                                            <div>
                                                <Col className=''>
                                                    <Button className="w-100 btn-cosmos-primary p-3" type="submit">
                                                        Login
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </section>
    )
}

export default Login