import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { primaryColor } from "./colorPallete"

const MySwal = withReactContent(Swal)

export const SwalLoading = (title = "Loading Data") => {
    MySwal.fire({
        title: <p>{title}</p>,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCloseButton: false,
        showConfirmButton: false,
        didOpen: () => {
            MySwal.showLoading()
        },
    })
}

export const SwalClose = () => {
    MySwal.close()
}

export const SwalFire = (title, html = "", icon, onConfirm=()=>{}) => {
    MySwal.fire({
        title: title,
        html: html,
        icon: icon,
        confirmButtonColor: primaryColor()
    }).then(() => {
        onConfirm()
    })
}

export const SwalConfirmation = ({title="Are You Sure ?", text="", icon="warning", confirmButtonColor=primaryColor(), cancelButtonColor='#d33', confirmButtonText="Continue", onConfirm=()=>{}}) => {
    MySwal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: confirmButtonText
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm()
        }
    })
}